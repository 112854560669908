import React from "react";
import { DotLottieReact } from "@lottiefiles/dotlottie-react";
import lottieBanner from "../assets/Flight2.lottie";
import { MDBBtn } from "mdb-react-ui-kit";

const IntroSection = () => {
  return (
    <div className="section pt-4 pb-4">
      <div className="container">
        <div className="d-flex">
          <div className="leftSection  pt-4 pb-4" style={{ textAlign: "left" }}>
            <h1 className="heading">Book Your Perfect Experience with Ease</h1>
            <p>
              At Holywoodprocrew, we believe booking your next adventure should
              be simple, stress-free, and tailored to your needs. Whether you’re
              planning a weekend getaway, a business trip, or a special event,
              we offer a wide range of booking services to help you secure the
              best options.
            </p>

            <a href="/login">
              <MDBBtn size="lg" className="me-1">
                Book Now
              </MDBBtn>
            </a>
          </div>
          <div className="leftSection  pt-4 pb-4">
            <DotLottieReact src={lottieBanner} loop autoplay />
          </div>
        </div>
      </div>
    </div>
  );
};

export default IntroSection;
