import React from "react";
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardImage,
  MDBBtn,
} from "mdb-react-ui-kit";

export default function Card({ image, title, desc, link, button }) {
  return (
    <MDBCard>
      <MDBCardImage src={image} position="top" alt="..." />
      <MDBCardBody>
        <MDBCardTitle>{title}</MDBCardTitle>
        <MDBCardText>{desc}</MDBCardText>
        <MDBBtn href={link}>{button}</MDBBtn>
      </MDBCardBody>
    </MDBCard>
  );
}
