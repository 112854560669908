import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Banner from "../components/Banner";
import ProfilePage from "../components/UserProfile";
import Footer from "../components/Footer";
import { auth, db } from "../components/firebase";
import { doc, getDoc } from "firebase/firestore";

const Booking = () => {
  const [userDetails, setUserDetails] = useState(null);
  const fetchUserData = async () => {
    auth.onAuthStateChanged(async (user) => {
      const docRef = doc(db, "Users", user.uid);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setUserDetails(docSnap.data());
        console.log(docSnap.data());
      } else {
        console.log("User is not logged in");
      }
    });
  };
  useEffect(() => {
    fetchUserData();
  }, []);
  async function handleLogout() {
    try {
      await auth.signOut();
      window.location.href = "/login";
      console.log("User logged out successfully!");
    } catch (error) {
      console.error("Error logging out:", error.message);
    }
  }
  return (
    <div>
      <Header user={userDetails} />
      <Banner user={userDetails} />
      <ProfilePage user={userDetails} />
      {userDetails ? <></> : <p>Loading....</p>}
      <Footer logout={handleLogout} />
    </div>
  );
};

export default Booking;
