import React, { useEffect, useRef, useState } from "react";
import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCardText,
  MDBCardBody,
  MDBCardImage,
  MDBBtn,
  MDBFile,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from "mdb-react-ui-kit";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { db, storage } from "./firebase";
import { collection, doc, getDocs, query, updateDoc } from "firebase/firestore";
import { toast } from "react-toastify";
import profileImg from "../assets/profile.png";

export default function ProfilePage({ user }) {
  const [photo, setPhoto] = useState("");
  const [file, setFile] = useState(null);
  const [bonus, setBonus] = useState(null);

  const [open, setOpen] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    if (open) {
      inputRef.current?.focus();
    }
  }, [open]);
  // upload image to firestore
  useEffect(() => {
    const uploadFile = () => {
      const storageRef = ref(storage, file.name);
      const uploadTask = uploadBytesResumable(storageRef, file);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("upload is" + progress + "% done");
          //   setProgress(progress);
          switch (snapshot.state) {
            case "pause":
              console.log("upload is stoped");
              break;
            case "running":
              console.log("upload is running");
              break;
            default:
              break;
          }
        },
        (error) => {
          console.log(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadUrl) => {
            setPhoto(downloadUrl);
            addToDb();
            // console.log(downloadUrl);
          });
        }
      );
    };
    file && uploadFile();
  }, [file]);

  // get document
  useEffect(() => {
    const getBonus = async (e) => {
      let topList = [];
      const Posts = query(collection(db, "bonus"));
      const querySnapshot = await getDocs(Posts);
      querySnapshot.forEach((doc) => {
        topList.push(doc.data());
      });

      setBonus(topList[0].amount);
    };
    getBonus();
  }, [user?.id]);

  console.log(bonus);
  const addToDb = async () => {
    // console.log(user.id);
    try {
      await updateDoc(doc(db, "Users", user.id), {
        photo: photo,
      });
      setOpen(!open);
      toast.success("Photo Uploaded Successfully");
    } catch (err) {
      console.log(err);
    }
  };
  //   console.log(user?.email);
  const openChat = (e) => {
    const chat = document.getElementById("tawk-bubble-container");
    console.log(chat);
  };
  return (
    <section style={{ backgroundColor: "#eee" }}>
      <MDBModal open={open} setOpen={setOpen} tabIndex={-1}>
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Upload Image</MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={() => setOpen(!open)}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              {/* <MDBInput ref={inputRef} /> */}
              <MDBRow>
                <MDBCol lg="9">
                  <MDBFile
                    id="customFile"
                    accept="image/png,image/jpeg"
                    type="file"
                    onChange={(e) => setFile(e.target.files[0])}
                  />
                </MDBCol>
              </MDBRow>
            </MDBModalBody>

            <MDBModalFooter>
              <MDBBtn type="button" onClick={() => setOpen(!open)}>
                Close
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
      <br />
      <br />
      <div className="container" style={{ textAlign: "left" }}>
        <h3>Apply for a free {bonus} bonus for your booking</h3>
        {/* <MDBBtn className="me-1">Primary</MDBBtn> */}
        <a
          href="https://tawk.to/chat/672a75334304e3196adda278/1ibutjl7u"
          target="_blank"
          rel="noopener noreferrer"
        >
          <MDBBtn className="me-1" color="secondary">
            Apply Now!
          </MDBBtn>
        </a>
        {/* <MDBBtn className="mx-2" color="tertiary" rippleColor="light">
          Tertiary
        </MDBBtn> */}
      </div>
      <MDBContainer className="py-5">
        <MDBRow>
          <MDBCol lg="4">
            <MDBCard className="mb-4">
              <MDBCardBody className="text-center">
                <div className="relt">
                  {user?.photo ? (
                    ""
                  ) : (
                    <i
                      onClick={() => setOpen(!open)}
                      class="fa-solid fa-pen-to-square"
                    ></i>
                    // <i
                    //   class="fa-solid fa-user-circle"
                    //   style={{ fontSize: "150px", color: "#333" }}
                    // ></i>
                  )}

                  <MDBCardImage
                    src={!user?.photo ? profileImg : user?.photo}
                    alt="avatar"
                    className="rounded-circle"
                    style={{ width: "150px", height: "150px" }}
                    fluid
                  />
                </div>

                <p className="text-muted mb-1">
                  Hi, {user?.firstName} {user?.lastName}
                </p>
                {/* <MDBRow>
                  <MDBCol lg="9">
                    <MDBFile
                      id="customFile"
                      accept="image/png,image/jpeg"
                      type="file"
                      onChange={(e) => setFile(e.target.files[0])}
                    />
                  </MDBCol>
                  <MDBCol>Update</MDBCol>
                </MDBRow> */}
                <p className="text-muted mb-4">{user?.email}</p>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
          <MDBCol lg="8">
            <MDBCard className="mb-4">
              <MDBCardBody>
                <MDBRow>
                  <MDBCol sm="3">
                    <MDBCardText>Full Name</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="9">
                    <MDBCardText className="text-muted">
                      {user?.firstName} {user?.lastName}
                    </MDBCardText>
                  </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                  <MDBCol sm="3">
                    <MDBCardText>Email</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="9">
                    <MDBCardText className="text-muted">
                      {user?.email}
                    </MDBCardText>
                  </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                  <MDBCol sm="3">
                    <MDBCardText>Phone</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="9">
                    <MDBCardText className="text-muted">
                      {user?.phone}
                    </MDBCardText>
                  </MDBCol>
                </MDBRow>

                <hr />
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </section>
  );
}
