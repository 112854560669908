import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

const About = () => {
  return (
    <>
      <Header />
      <div className="container">
        <div className="auth-wrapper">
          <br />
          <br />
          <div className="page-text">
            <h1>About Us</h1>
            At HollywoodProCrew, we specialize in delivering seamless and
            stress-free flight booking services for both professionals and
            enthusiasts in the entertainment industry. <br /> <br />
            Whether you're a film crew, production team, or talent heading to
            your next project, we’ve got you covered. Our mission is to provide
            personalized travel solutions tailored to your unique needs,
            ensuring that your journey is as smooth as your production. <br />{" "}
            <br /> With a focus on efficiency, reliability, and convenience,
            HollywoodProCrew offers a range of services designed to meet the
            fast-paced demands of the entertainment world. <br />
            From booking the most cost-effective flights to ensuring flexible
            schedules and last-minute adjustments, we handle the details so you
            can focus on what matters most – bringing your vision to life. Our
            dedicated team understands the intricacies of the industry and works
            closely with you to manage all aspects of your travel. <br /> <br />{" "}
            Whether you're flying across the globe or just across town, trust
            HollywoodProCrew to get you there on time, every time. <br />
            <br />
            Let us handle the logistics, so you can keep your eyes on the big
            picture. Welcome aboard HollywoodProCrew – your travel partner in
            the world of entertainment! <br />
          </div>
        </div>
      </div>
      <br />
      <br />
      <Footer />
    </>
  );
};

export default About;
