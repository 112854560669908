// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDJOiUOdrt-CIB4K1VS8GSRX2N5-PTqSgk",
  authDomain: "testifypro-7983b.firebaseapp.com",
  databaseURL: "https://testifypro-7983b-default-rtdb.firebaseio.com",
  projectId: "testifypro-7983b",
  storageBucket: "testifypro-7983b.appspot.com",
  messagingSenderId: "1075302473115",
  appId: "1:1075302473115:web:24b9e6a87d10db48bf153e",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth();
export const storage = getStorage();
export const db = getFirestore(app);
export default app;
