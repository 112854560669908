import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

const Policy = () => {
  return (
    <>
      <Header />
      <div className="container">
        <div className="auth-wrapper">
          <br />
          <br />
          <div className="page-text">
            <h1>Privacy Policy</h1>
            Last updated: 2024 <br />
            Holywoodprocrew ("we", "our", "us") is committed to protecting your
            privacy. This Privacy Policy outlines how we collect, use, and
            protect your personal information when you visit our website
            www.holywoodprocrew.co.ke ("the Site"). <br />
            By using the Site, you agree to the collection and use of
            information in accordance with this policy.
            <ul>
              <br />
              <h5>1. Information We Collect</h5>
              <li>
                We may collect the following types of information when you visit
                our Site: <br />
                - Personal Information: This includes details that can identify
                you personally, such as your name, email address, phone number,
                and payment information. This information is collected when you
                sign up for an account, subscribe to our newsletter, make a
                purchase, or contact us for support. <br />- Non-Personal
                Information: This includes data that cannot directly identify
                you, such as browser type, IP address, device information, and
                usage statistics. We may also use cookies to enhance your
                experience.
              </li>
              <br />
              <li>
                <h5>How We Use Your Information</h5> We use the information we
                collect for the following purposes: To provide and maintain our
                services, including processing transactions and providing
                customer support. To personalize your experience and improve the
                functionality of the Site. To communicate with you, including
                sending marketing emails, newsletters, and updates about our
                products and services. To monitor and analyze usage patterns to
                improve the Site's performance and user experience. To comply
                with legal obligations and enforce our terms and conditions.
              </li>
              <br />
              <li>
                <h5>Cookies and Tracking Technologies</h5>
                We use cookies and similar tracking technologies to track the
                activity on our Site and hold certain information. Cookies are
                small files stored on your device that help us analyze website
                traffic and improve user experience. You can set your browser to
                refuse cookies, but this may limit certain features of the Site.
              </li>

              <br />
              <li>
                <h5>Data Sharing and Disclosure</h5> We do not sell, rent, or
                trade your personal information to third parties. However, we
                may share your data in the following cases: Service Providers:
                We may share your information with trusted third-party vendors
                who assist in operating our Site, such as payment processors,
                hosting services, or analytics providers. Legal Compliance: We
                may disclose your information if required by law or in response
                to valid requests by public authorities, such as a court or
                government agency. Business Transfers: In the event of a merger,
                acquisition, or sale of assets, your personal information may be
                transferred.
              </li>

              <br />
              <li>
                <h5>Data Security</h5> We take reasonable steps to protect your
                personal information from unauthorized access, alteration, or
                disclosure. However, no method of transmission over the internet
                or electronic storage is 100% secure, and we cannot guarantee
                absolute security.
              </li>
              <br />
              <li>
                <h5>Your Rights and Choices</h5> You have the following rights
                regarding your personal information: Access: You can request a
                copy of the personal data we hold about you. Correction: You can
                update or correct any incorrect or incomplete personal
                information. Deletion: You can request that we delete your
                personal data, subject to legal or contractual obligations.
                Opt-out of Marketing: You can opt out of receiving marketing
                emails by following the unsubscribe link in our emails or by
                contacting us directly. To exercise any of these rights, please
                contact us at contact@holywoodprocrew.co.ke.
              </li>

              <br />
              <li>
                <h5>Third-Party Links</h5> Our Site may contain links to other
                websites that are not operated by us. We are not responsible for
                the privacy practices or content of these third-party sites. We
                encourage you to review the privacy policies of any third-party
                websites you visit.
              </li>

              <br />
              <li>
                <h5>Children’s Privacy</h5> Our Site is not intended for
                children under the age of 13, and we do not knowingly collect
                personal information from children. If we learn that we have
                collected personal data of a child under 13, we will take steps
                to delete that information.
              </li>

              <br />
              <li>
                <h5>Changes to This Privacy Policy</h5> We may update our
                Privacy Policy from time to time. Any changes will be posted on
                this page with an updated "Last Updated" date. We encourage you
                to review this Privacy Policy periodically to stay informed
                about how we are protecting your information.
              </li>
            </ul>
            Contact Us If you have any questions about this Privacy Policy or
            our privacy practices, please contact us
          </div>
        </div>
      </div>
      <br />
      <br />
      <Footer />
    </>
  );
};

export default Policy;
