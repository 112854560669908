import React from "react";

const Why = () => {
  return (
    <div>
      <div class="feat bg-gray pt-5 pb-5">
        <div class="container">
          <div class="row">
            <div class="section-head col-sm-12">
              <h4>
                <span>Why Choose</span> Us?
              </h4>
            </div>
            <div class="col-lg-4 col-sm-6">
              <div class="item">
                {" "}
                <span class="icon feature_box_col_one">
                  <i class="fa fa-globe"></i>
                </span>
                <h6>Instant Availability</h6>
                <p>
                  Find and book your perfect accommodations, flights, or events
                  in just a few clicks.
                </p>
              </div>
            </div>
            <div class="col-lg-4 col-sm-6">
              <div class="item">
                {" "}
                <span class="icon feature_box_col_two">
                  <i class="fa fa-anchor"></i>
                </span>
                <h6>Exclusive Deals</h6>
                <p>
                  Enjoy discounted rates and special offers, only available
                  through our platform.
                </p>
              </div>
            </div>
            <div class="col-lg-4 col-sm-6">
              <div class="item">
                {" "}
                <span class="icon feature_box_col_three">
                  <i class="fa fa-hourglass-half"></i>
                </span>
                <h6>24 x 7 User Support</h6>
                <p>
                  If our customer has any problem and any query we are always
                  happy to help then.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Why;
