import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { MDBFile } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import { auth, db, storage } from "../components/firebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import Photo from "../assets/camera.png";
import Loader from "../components/Loader";

const UploadPhoto = () => {
  const [photo, setPhoto] = useState("");
  const [file, setFile] = useState(null);
  const [user, setUser] = useState(null);
  const [preview, setPreview] = useState();
  const [loading, setLoading] = useState(false);

  const fetchUserData = async () => {
    auth.onAuthStateChanged(async (user) => {
      const docRef = doc(db, "Users", user.uid);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setUser(docSnap.data());
        console.log(docSnap.data());
      } else {
        console.log("User is not logged in");
      }
    });
  };

  const addToDb = async () => {
    setLoading(true);
    console.log(photo);
    try {
      await updateDoc(doc(db, "Users", user.id), {
        photo: photo,
      });
      window.location.href = "/profile";
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    fetchUserData();
    const uploadFile = () => {
      const storageRef = ref(storage, file.name);
      const uploadTask = uploadBytesResumable(storageRef, file);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("upload is" + progress + "% done");
          setLoading(true);
          switch (snapshot.state) {
            case "pause":
              console.log("upload is stoped");
              break;
            case "running":
              console.log("upload is running");
              break;
            default:
              break;
          }
        },
        (error) => {
          console.log(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadUrl) => {
            // console.log(downloadUrl);
            setPhoto(downloadUrl);
            setLoading(false);
            // addToDb();
            // console.log(downloadUrl);
          });
        }
      );
    };
    file && uploadFile();
  }, [file]);

  useEffect(() => {
    if (!file) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(file);
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [file]);

  // console.log(user);
  return (
    <div className="auth-wrapper">
      <div className="auth-inner">
        <div id="second" className="firstForm">
          <div className="photo">
            <img className="rounded" src={preview ? preview : Photo} />
          </div>
          <br />

          <MDBFile
            onChange={(e) => setFile(e.target.files[0])}
            label="Upload Photo"
            size="lg"
            id="formFileLg"
          />
          <br />
          <div className="d-grid">
            <button
              onClick={addToDb}
              disabled={loading}
              type="submit"
              className="btn btn-primary"
            >
              {loading ? <Loader /> : "Upload"}
            </button>
          </div>
        </div>
        <br />

        <p className="forgot-password text-right">
          Already registered <a href="/login">Login</a>
        </p>
      </div>
    </div>
  );
};

export default UploadPhoto;
