import React, { useEffect, useState } from "react";
import {
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane,
  MDBIcon,
  MDBInput,
  MDBBtn,
} from "mdb-react-ui-kit";
import Photo from "../assets/flight.jpg";
import Countries from "./Countries";
import Reservation from "./Reservations";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  serverTimestamp,
} from "firebase/firestore";
import { auth, db } from "./firebase";
import { toast } from "react-toastify";
import Loader from "./Loader";

export default function Banner() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [iconsActive, setIconsActive] = useState("tab1");

  const fetchUserData = async () => {
    auth.onAuthStateChanged(async (user) => {
      const docRef = doc(db, "Users", user.uid);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setUser(docSnap.data());
        console.log(docSnap.data());
      } else {
        console.log("User is not logged in");
      }
    });
  };
  useEffect(() => {
    fetchUserData();
  }, []);
  // console.log(user);
  const handleIconsClick = (value) => {
    if (value === iconsActive) {
      return;
    }
    setIconsActive(value);
  };

  const [formValue, setFormValue] = useState({
    fullName: "",
    email: "",
    phone: "",
    jobTitle: "",
    seatNumber: "",
    from: "",
    to: "",
    celebreteToMeet: "",
    status: false,
  });

  const handleSend = async (e) => {
    // console.log(formValue);
    setLoading(true);
    if (
      formValue.fullName &&
      formValue.email &&
      formValue.phone &&
      formValue.jobTitle &&
      formValue.seatNumber &&
      formValue.from &&
      formValue.to
    ) {
      try {
        await addDoc(collection(db, "Reservations"), {
          ...formValue,
          user: user.id,
          date: serverTimestamp(),
        });
        setLoading(false);
        toast.success("Booking Created Successfully");
        window.location.reload();
      } catch (err) {
        toast.error("Not Added" + err);
      }
    } else {
      toast.error("Please Fill All Fields");
    }
  };

  const onChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
    console.log(formValue);
  };

  console.log(user);
  return (
    <div class="banner">
      <div
        id="intro-example"
        className="p-5 text-center bg-image"
        style={{
          backgroundImage: `url(${Photo})`,
        }}
      >
        <div className="mask" style={{ backgroundColor: "rgba(0, 0, 0, 0.7)" }}>
          <div className="w-500 d-flex justify-content-center align-items-center h-100">
            <div className="text-white" style={{ background: "white" }}>
              <MDBTabs className="mb-3 w-1000">
                <MDBTabsItem>
                  <MDBTabsLink
                    onClick={() => handleIconsClick("tab1")}
                    active={iconsActive === "tab1"}
                  >
                    <MDBIcon fas icon="plane-circle-check" className="me-2" />{" "}
                    Book Flight
                  </MDBTabsLink>
                </MDBTabsItem>
                <MDBTabsItem>
                  <MDBTabsLink
                    onClick={() => handleIconsClick("tab2")}
                    active={iconsActive === "tab2"}
                  >
                    <MDBIcon fas icon="list-check" className="me-2" /> My
                    Bookings
                  </MDBTabsLink>
                </MDBTabsItem>
              </MDBTabs>

              <MDBTabsContent>
                <MDBTabsPane open={iconsActive === "tab1"}>
                  <h3 style={{ color: "#000" }}>Book a Flight</h3>
                  <div className="row">
                    <MDBInput
                      label="Full Name"
                      id="formControlLg"
                      type="text"
                      name="fullName"
                      size="lg"
                      onChange={onChange}
                      placeholder={user?.firstName}
                    />
                    <MDBInput
                      label="Email Address"
                      id="formControlLg"
                      type="email"
                      name="email"
                      size="lg"
                      onChange={onChange}
                      placeholder={user?.email}
                    />

                    <MDBInput
                      label="Phone Number"
                      id="formControlLg"
                      type="number"
                      size="lg"
                      name="phone"
                      onChange={onChange}
                    />
                    <MDBInput
                      label="Celebrity to meet"
                      id="formControlLg"
                      type="text"
                      size="lg"
                      name="celebreteToMeet"
                      onChange={onChange}
                    />
                  </div>
                  <br />
                  <div className="row">
                    <MDBInput
                      label="Job Title"
                      id="formControlLg"
                      type="text"
                      size="lg"
                      name="jobTitle"
                      onChange={onChange}
                    />
                    <MDBInput
                      label="Seat Number"
                      id="formControlLg"
                      type="text"
                      size="lg"
                      name="seatNumber"
                      onChange={onChange}
                    />
                    <select
                      id="country"
                      name="from"
                      onChange={onChange}
                      class="form-control form-outline"
                    >
                      <Countries label="Traveling from" />
                    </select>

                    <select
                      id="country"
                      name="to"
                      onChange={onChange}
                      class="form-control form-outline"
                    >
                      <Countries label="Traveling to" />
                    </select>
                  </div>
                  <br />
                  {/* button  */}
                  <div className="row">
                    <MDBBtn
                      disabled={loading}
                      onClick={handleSend}
                      color="primary"
                      size="lg"
                      block
                      className="mb-3"
                    >
                      {loading ? <Loader /> : "Book Now"}
                    </MDBBtn>
                  </div>
                </MDBTabsPane>
                <MDBTabsPane open={iconsActive === "tab2"}>
                  <Reservation user={user} />
                </MDBTabsPane>
              </MDBTabsContent>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
