import React, { useState } from "react";
import { MDBBtn } from "mdb-react-ui-kit";
import { DotLottieReact } from "@lottiefiles/dotlottie-react";
import lottieBanner from "../assets/lottiebg.lottie";

const Jumborton = () => {
  return (
    <div className="jumb bg-light">
      <div className="container text-light">
        <div className="d-flex">
          <div className="content" style={{ flex: 1 }}>
            <h1 className="mb-3">
              Explore vacation packages to popular destinations
            </h1>
            <p className="mb-3 lead">
              Earn up to $500 in OneKeyCash™ after a qualified booking. Terms
              apply. OneKeyCash is not redeemable for cash.
            </p>
            <br />
            <a className="" href="/login" role="button">
              <MDBBtn
                outline
                rounded
                className="mx-2 mt-2"
                color="secondary text-light"
                size="lg"
              >
                Get Started
              </MDBBtn>
            </a>
          </div>

          <div className="lottie" style={{ flex: 1 }}>
            <DotLottieReact src={lottieBanner} loop autoplay />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Jumborton;
