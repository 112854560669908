import React from "react";
import Header from "../components/Header";
import Jumborton from "../components/Jumburton";
import IntroSection from "../components/IntroSection";
import Posts from "../components/Posts";
import Footer from "../components/Footer";
import Why from "../components/Why";

function Landing() {
  return (
    <div>
      <Header page={"home"} />
      <Jumborton />
      <IntroSection />
      <Why />
      <Posts />
      <br />
      <Footer />
    </div>
  );
}

export default Landing;
