import React, { useState } from "react";
import { auth } from "./firebase";
import {
  MDBBadge,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBNavbarNav,
} from "mdb-react-ui-kit";
import profileImg from "../assets/profile.png";
import logo from "../assets/logo.png";

const Header = ({ user, page }) => {
  const [isOpen, setIsOpen] = useState(false);
  // console.log(user);
  async function handleLogout() {
    try {
      await auth.signOut();
      window.location.href = "/login";
      console.log("User logged out successfully!");
    } catch (error) {
      console.error("Error logging out:", error.message);
    }
  }

  const goToLogin = () => {
    window.location.href = "/login";
  };

  return (
    <>
      <div className={`menu container ${isOpen ? "ShowMobile" : "hideMobile"}`}>
        <h1>Menu</h1>
        <hr />
        <ul style={{ listStyle: "none", padding: 0 }}>
          <li className="menuItem">
            <a className="mobMenu" href="/">
              Home
            </a>
          </li>
          <li className="menuItem">
            <a className="mobMenu" href="/about-us">
              About
            </a>
          </li>
          <li className="menuItem">
            <a className="mobMenu" href="/contact-us">
              Support
            </a>
          </li>
          <li className="menuItem">
            <a className="mobMenu" href="/register">
              Register
            </a>
          </li>
          <li className="menuItem">
            <a className="mobMenu" href="/login">
              Login
            </a>
          </li>
          <li className="menuItem">
            <a className="mobMenu" href="/policy">
              Privacy Policy
            </a>
          </li>
        </ul>
      </div>
      <nav class="navbar navbar-expand-lg navbar-light bg-body-tertiary">
        <div class="container">
          <a class="navbar-brand me-2" href="/">
            <img
              src={logo}
              alt=""
              loading="lazy"
              style={{ marginTop: "-1px" }}
            />
          </a>

          <button
            onClick={() => setIsOpen(!isOpen)}
            data-mdb-collapse-init
            class="navbar-toggler"
            type="button"
            data-mdb-target="#navbarButtonsExample"
            aria-controls="navbarButtonsExample"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i class="fas fa-bars"></i>
          </button>

          <div class="collapse navbar-collapse" id="navbarButtonsExample">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0"></ul>

            <div class="d-flex align-items-center">
              <ul className="navLink">
                <li className="link active">
                  <a href="/" className="linkItem active">
                    Home
                  </a>
                </li>
                <li className="link active">
                  <a href="/about-us" className="linkItem">
                    About us
                  </a>
                </li>{" "}
                <li className="link ">
                  <a href="/contact-us" className="linkItem active">
                    Get in Touch
                  </a>
                </li>
                <li className="link ">
                  <a href="/policy" className="linkItem active">
                    Privacy Policy
                  </a>
                </li>
              </ul>

              {user?.isAdmin ? (
                <>
                  <a
                    href="/backend"
                    data-mdb-ripple-init
                    type="button"
                    class="btn btn-link px-3 me-2"
                  >
                    Admin <br />
                  </a>
                </>
              ) : (
                ""
              )}
              {user ? (
                <>
                  <div className="d-inline-flex position-relative">
                    <MDBBadge className="position-absolute top-0 start-100 translate-middle p-1 bg-danger border border-light rounded-circle">
                      <span className="visually-hidden">New alerts</span>
                    </MDBBadge>
                    <img
                      className="rounded-8 shadow-8"
                      src={!user?.photo ? profileImg : user?.photo}
                      alt="Avatar"
                      style={{ width: "40px", height: "40px" }}
                    />
                  </div>
                  <button
                    data-mdb-ripple-init
                    type="button"
                    class="btn btn-link px-3 me-2"
                  >
                    Hi {user?.firstName} {user?.lastName} <br />
                  </button>

                  <button
                    data-mdb-ripple-init
                    type="button"
                    class="btn btn-primary me-3"
                    onClick={handleLogout}
                  >
                    Logout
                  </button>
                </>
              ) : (
                <>
                  <button
                    data-mdb-ripple-init
                    type="button"
                    class="btn btn-primary me-3"
                    onClick={goToLogin}
                  >
                    Sign In
                  </button>
                </>
              )}

              <a
                data-mdb-ripple-init
                class="btn btn-dark px-3"
                href="https://github.com/mdbootstrap/mdb-ui-kit"
                role="button"
              >
                <i class="fab fa-github"></i>
              </a>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
