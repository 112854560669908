import { MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";
import React from "react";
import Card from "./Card";

const Posts = () => {
  return (
    <div className="section">
      <div className="container">
        <h1>Latest Posts</h1>
        <br />
        <MDBContainer>
          <MDBRow>
            <MDBCol size="md">
              <Card
                image="https://www.adventurouskate.com/wp-content/uploads/2024/11/San-Gimignano-Birds-Eye-View-Things-to-Do-in-San-Gimignano-Italy-400x300.jpg.webp"
                link="/"
                button="See more"
                title="14 Fab Things to Do in San Gimignano, Italy"
                desc="People come to San Gimignano to enjoy its stone architecture and medieval towers; its museums and views — oh yes, and one particular gelato shop."
              />
            </MDBCol>
            <MDBCol size="md">
              <Card
                image="https://www.adventurouskate.com/wp-content/uploads/2024/11/Kate-at-Tigers-Nest-Travel-to-Bhutan-400x300.jpg.webp"
                link="/"
                button="See more"
                title="What’s it Really Like to Travel to Bhutan?"
                desc="Travel to Bhutan and you’ll enjoy one of the most special and unusual destinations this planet has to offer. "
              />
            </MDBCol>
            <MDBCol size="md">
              <Card
                image="https://www.adventurouskate.com/wp-content/uploads/2024/11/Leaning-Tower-at-a-Distance-Things-to-Do-in-Pisa-Italy-400x300.jpg.webp"
                link="/"
                button="See more"
                title="16 Fun Things to Do in Pisa, Italy"
                desc="There’s so much more to Pisa than the Leaning Tower. There are so many cool things to do in Pisa — this is a big, bustling city with a mind"
              />
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </div>
    </div>
  );
};

export default Posts;
