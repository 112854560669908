import React, { useEffect, useState } from "react";
import {
  MDBBadge,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
} from "mdb-react-ui-kit";
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { db } from "./firebase";
import profileImg from "../assets/profile.png";
import { toast } from "react-toastify";
import ReservationCard from "./ReservationCard";

export default function Reservation({ user }) {
  const [reservations, setReservations] = useState([]);
  // console.log(user?.id);
  useEffect(() => {
    const getData = async (e) => {
      let topList = [];
      const Posts = query(
        collection(db, "Reservations"),
        where("user", "==", user?.id),
        orderBy("date", "desc")
      );
      const querySnapshot = await getDocs(Posts);
      querySnapshot.forEach((doc) => {
        topList.push({ id: doc.id, ...doc.data() });
      });

      setReservations(topList);
    };
    getData();
  }, [user?.id]);

  const handleBlogDelete = async (id) => {
    if (window.confirm("are you sure you want to delete this post?")) {
      try {
        await deleteDoc(doc(db, "Reservations", id));
        toast.success("Booking Deleted");
        window.location.reload();
      } catch (err) {
        console.log(err);
      }
    }
  };
  // console.log(reservations);
  return (
    <>
      <MDBTable align="middle" className="	d-none d-lg-block ">
        <MDBTableHead>
          <tr>
            <th scope="col">Name</th>
            <th scope="col">From</th>
            <th scope="col">To</th>
            <th scope="col">Date</th>
            <th scope="col">Status</th>
            <th scope="col">action</th>
          </tr>
        </MDBTableHead>
        <MDBTableBody>
          {reservations?.map((reservation) => (
            <tr key={reservation.id}>
              <td>
                <div className="d-flex align-items-center">
                  <img
                    src={user?.photo ? user?.photo : profileImg}
                    alt=""
                    style={{ width: "40px", height: "40px" }}
                    className="rounded-circle"
                  />
                  <div className="ms-1">
                    <p className="fw-bold mb-0">{reservation.fullName}</p>
                    <p className="text-muted mb-0">{reservation.email}</p>
                  </div>
                </div>
              </td>
              <td>{reservation.from}</td>
              <td>{reservation.to}</td>
              <td>{new Date(reservation?.date * 1000).toDateString()}</td>
              <td>
                {reservation.status ? (
                  <MDBBadge color="success" pill>
                    Approved
                  </MDBBadge>
                ) : (
                  <MDBBadge color="warning" pill>
                    Pending
                  </MDBBadge>
                )}
              </td>
              <td>
                <i
                  style={{ cursor: "pointer" }}
                  onClick={() => handleBlogDelete(reservation.id)}
                  class="fa fa-trash"
                ></i>
              </td>
            </tr>
          ))}
        </MDBTableBody>
      </MDBTable>

      <ReservationCard
        data={reservations}
        handleBlogDelete={handleBlogDelete}
      />
    </>
  );
}
