import React from "react";
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardHeader,
  MDBCardFooter,
  MDBBtn,
  MDBBadge,
  MDBListGroup,
  MDBListGroupItem,
} from "mdb-react-ui-kit";
import profileImg from "../assets/profile.png";

export default function ReservationCard({ data, user, handleBlogDelete }) {
  return (
    // Replace the following with your own reservation card component
    <>
      {data?.map((reservation) => (
        <MDBCard
          className="mb-2 d-block d-sm-none"
          style={{ color: "black" }}
          key={reservation.id}
        >
          <MDBCardBody>
            <MDBCardTitle>{reservation.fullName}</MDBCardTitle>
            <MDBListGroup flush>
              <MDBListGroupItem>{reservation.from}</MDBListGroupItem>
              <MDBListGroupItem>{reservation.to}</MDBListGroupItem>
              <MDBListGroupItem>
                <span>Status:</span>
                {reservation.status ? (
                  <MDBBadge color="success" pill>
                    Approved
                  </MDBBadge>
                ) : (
                  <MDBBadge color="warning" pill>
                    Pending
                  </MDBBadge>
                )}
              </MDBListGroupItem>
            </MDBListGroup>
            <MDBCardText>Your Application is currently processing</MDBCardText>
          </MDBCardBody>
          <MDBCardFooter className="text-muted">
            <br />
            <i
              style={{ cursor: "pointer" }}
              onClick={() => handleBlogDelete(reservation.id)}
              class="fa fa-trash"
            >
              <span>Delete</span>
            </i>
          </MDBCardFooter>
        </MDBCard>
      ))}
    </>
  );
}
