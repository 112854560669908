import React, { useState } from "react";
import Header from "../components/Header";
import { MDBInput, MDBTextArea } from "mdb-react-ui-kit";
import { toast } from "react-toastify";
import { addDoc, collection, doc } from "firebase/firestore";
import { db } from "../components/firebase";

const Contact = () => {
  const [email, setEmail] = useState("");
  const [fullName, setName] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const handleMessage = async (e) => {
    e.preventDefault();
    console.log(email, fullName, subject, message);
    try {
      await addDoc(collection(db, "Contacts"), {
        name: fullName,
        email: email,
        subject: subject,
        message: message,
      });

      toast.success("Thanks, Message Sent");
      setTimeout(() => {
        window.location.href = "/contact-us";
      }, 3000);
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      <Header />
      <div className="auth-wrapper">
        <div className="auth-inner">
          <form onSubmit={handleMessage}>
            <h2 className="git">Get in Touch</h2>
            <br />
            <div className="mb-4">
              <MDBInput
                onChange={(e) => setName(e.target.value)}
                label="Full Name"
                id="typeText"
                type="text"
              />
            </div>

            <div className="mb-4">
              <MDBInput
                label="Email Address"
                id="typeEmail"
                type="email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <div className="mb-4">
              <MDBInput
                onChange={(e) => setSubject(e.target.value)}
                label="Subject"
                id="typeText"
                type="text"
              />
            </div>

            <div className="mb-3">
              <MDBTextArea
                placeholder="Enter Message"
                onChange={(e) => setMessage(e.target.value)}
                label="Message"
                id="textAreaExample"
                rows="{6}"
              />
            </div>

            <div className="d-grid">
              <button type="submit" className="btn btn-primary">
                Sign Up
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Contact;
