import React, { useEffect, useState } from "react";
import { doc, getDoc } from "firebase/firestore";
import { auth, db } from "../components/firebase";
import Header from "../components/Header";
import Footer from "../components/Footer";
import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardText,
  MDBCardTitle,
} from "mdb-react-ui-kit";
import CC from "../components/CC";
import Banner from "../components/Banner";

function Profile() {
  const [userDetails, setUserDetails] = useState(null);
  const fetchUserData = async () => {
    auth.onAuthStateChanged(async (user) => {
      const docRef = doc(db, "Users", user.uid);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setUserDetails(docSnap.data());
        console.log(docSnap.data());
      } else {
        console.log("User is not logged in");
      }
    });
  };
  useEffect(() => {
    fetchUserData();
  }, []);

  async function handleLogout() {
    try {
      await auth.signOut();
      window.location.href = "/login";
      console.log("User logged out successfully!");
    } catch (error) {
      console.error("Error logging out:", error.message);
    }
  }
  return (
    <div>
      <Header user={userDetails} />
      <section style={{ backgroundColor: "#eee", padding: "10px" }}>
        <br />
        <br />
        <CC user={userDetails} />
        <br />
        <Banner />
        <br />
        <br />
      </section>

      <Footer logout={handleLogout} />
    </div>
  );
}
export default Profile;
