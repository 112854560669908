import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from "firebase/auth";
import React, { useState } from "react";
import { setDoc, doc } from "firebase/firestore";
import { toast } from "react-toastify";
import { auth, db } from "../components/firebase";
import Loader from "../components/Loader";
import { MDBInput } from "mdb-react-ui-kit";

function Register() {
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [membership, setMembership] = useState("Regular Membership");
  const [loading, setLoading] = useState(false);

  const handleRegister = async (e) => {
    e.preventDefault();
    setLoading(true);
    // console.log(membership);
    try {
      await createUserWithEmailAndPassword(auth, email, password);
      const user = auth.currentUser;
      // console.log(user);
      if (user) {
        await setDoc(doc(db, "Users", user.uid), {
          email: user.email,
          firstName: fname,
          lastName: lname,
          photo: "",
          membership: membership,
          phone: phone,
          isAdmin: false,
          id: user.uid,
          bonus: 0,
        });
      }
      // console.log("User Registered Successfully!!");

      try {
        await signInWithEmailAndPassword(auth, email, password);
        console.log("User logged in Successfully");
        setLoading(false);
        window.location.href = "/profile-photo";
      } catch (error) {
        console.log(error.message);
      }
    } catch (error) {
      console.log(error.message);
      toast.error(error.message, {
        position: "bottom-center",
      });
    }
  };

  const onChange = (e) => {
    setMembership(e.target.value);
  };

  return (
    <div className="auth-wrapper">
      <div className="auth-inner">
        <form onSubmit={handleRegister}>
          <h3>Sign Up</h3>

          <div className="mb-3">
            <MDBInput
              onChange={(e) => setFname(e.target.value)}
              label="First name"
              id="form1"
              type="text"
            />
          </div>

          <div className="mb-3">
            <MDBInput
              onChange={(e) => setLname(e.target.value)}
              label="Last name"
              id="form1"
              type="text"
            />
          </div>

          <div className="mb-3">
            <MDBInput
              onChange={(e) => setEmail(e.target.value)}
              label="Email Address"
              id="form1"
              type="email"
              required
            />
          </div>
          <div className="mb-3">
            <MDBInput
              onChange={(e) => setPhone(e.target.value)}
              label="Phone"
              id="typePhone"
              type="tel"
            />
          </div>
          <div className="mb-3">
            {/* <label>Membership Type</label> */}
            <select
              id="country"
              name="from"
              onChange={onChange}
              class="form-control "
            >
              <option defaultChecked value="Regular Membership">
                Regular Membership
              </option>
              <option value="Vip Membership">Vip Membership</option>
              <option value="Gold Premium Membership">
                Gold Premium Membership
              </option>
            </select>
          </div>

          <div className="mb-3">
            <MDBInput
              label="Password"
              id="typePassword"
              type="password"
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>

          <div className="d-grid">
            <button
              disabled={loading}
              type="submit"
              className="btn btn-primary"
            >
              {loading ? <Loader /> : "Sign Up"}
            </button>
          </div>
          <p className="forgot-password text-right">
            Already registered <a href="/login">Login</a>
          </p>
        </form>
      </div>
    </div>
  );
}
export default Register;
