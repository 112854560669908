import React from "react";
import { Link } from "react-router-dom";
import Logo from "../assets/logo.png";

const CC = ({ user }) => {
  return (
    <div class="credit-card">
      <div class="circle1"></div>
      <div class="circle2"></div>
      <a
        href={"https://tawk.to/chat/672a75334304e3196adda278/1ibutjl7u"}
        style={{ color: "white" }}
      >
        {user?.bonus > 0 ? (
          <div className="bonus-cc">Claim your ${user?.bonus} bonus</div>
        ) : (
          ""
        )}
      </a>
      <div class="head">
        <div>
          <div
            className="photo-cc"
            style={{ backgroundImage: `url(${user?.photo})` }}
          ></div>
        </div>
        <img src={Logo} style={{ width: "50%" }} />
        {/* <div>HOLYWOODPROCREW</div> */}
      </div>
      {/* <div class="number">
        <div>1234</div>
        <div>5678</div>
        <div>9123</div>
        <div>4567</div>
      </div> */}

      <div class="tail">
        <div
          style={{
            textAlign: "left",
            textTransform: "uppercase",
            width: "50%",
          }}
        >
          {user?.firstName} {user?.lastName}
          <div
            style={{
              fontSize: "12px",
              textTransform: "uppercase",
              textAlign: "left",
              zIndex: 9,
            }}
          >
            Tel: {user?.phone}
          </div>
        </div>

        <div class="exp">
          <span class="exp-date">{user?.membership}</span>
          <div
            style={{
              fontSize: "12px",
              textTransform: "lowercase",
              textAlign: "right",
              zIndex: 9,
            }}
          >
            {user?.email}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CC;
